import { createSlice } from "@reduxjs/toolkit";

export const ORG_SLICE_KEY = "org";

export const orgSlice = createSlice({
    name: ORG_SLICE_KEY,
    initialState: {
        currOrgId: null,
        userOrgs: null,
    },
    reducers: {
        setCurrOrgId: (state, action) => {
            state.currOrgId = action.payload;
        },
        setUserOrgs: (state, action) => {
            state.userOrgs = action.payload;
        },
    },
});

export const { setCurrOrgId, setUserOrgs } = orgSlice.actions;
export default orgSlice.reducer;
