import { createSlice } from "@reduxjs/toolkit";

export const AUTH_SLICE_KEY = "auth";

export const authSlice = createSlice({
    name: AUTH_SLICE_KEY,
    initialState: {
        isAuth: false,
        authStep: 1,
        authVia: null,
        phoneCountryCode: "",
        phoneNumber: "",
        email: "",
        verificationCode: "",
        captchaToken: null,
    },
    reducers: {
        setIsAuth: (state, action) => {
            state.isAuth = action.payload;
        },
        setAuthStep: (state, action) => {
            state.authStep = action.payload;
        },
        setAuthVia: (state, action) => {
            state.authVia = action.payload;
        },
        setPhoneData: (state, action) => {
            const { phoneCountryCode, phoneNumber } = action.payload;
            state.phoneCountryCode = phoneCountryCode;
            state.phoneNumber = phoneNumber;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setVerificationCode: (state, action) => {
            state.verificationCode = action.payload;
        },
        setCaptchaToken: (state, action) => {
            state.captchaToken = action.payload;
        },
        resetAuthState: (state) => {
            state.isAuth = false;
            state.authStep = 1;
            state.authVia = null;
            state.phoneCountryCode = 1;
            state.phoneNumber = "";
            state.email = "";
            state.verificationCode = "";
            state.captchaToken = null;
        },
        
    },
});

export const { 
    setIsAuth, 
    setAuthStep, 
    setAuthVia, 
    setPhoneData, 
    setEmail, 
    setVerificationCode,
    resetAuthState,
    setCaptchaToken
} = authSlice.actions;
export default authSlice.reducer;