import Request from '../../utils/api';
import { ASSISTANTS_LIST } from '../../utils/apiRoutes';

const api = new Request();

export const getAssistantsList = async (params, headers, onSuccess, onError) => {
	const response = await api.get(ASSISTANTS_LIST, params, headers);

	if (response.message === "Success" && response.data) {
        return typeof onSuccess === "function" ? onSuccess(response.data) : response.data;
    } else {
        return typeof onError === "function" ? onError(response) : response;
    }
};
