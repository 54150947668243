import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

function GenericCard({ title, value }) {
	return (
		<Box
			sx={{
				height: '160px',
				backgroundColor: '#fff',
				borderRadius: '8px',
				padding: '8px',
			}}
		>
			<Stack
				height={'100%'}
				direction={'column'}
				justifyContent={'space-evenly'}
				alignItems={'center'}
			>
				<Typography
					variant="h4"
					textAlign={'center'}
				>
					{value}
				</Typography>
				{
					(title !== "" && value !== "") && 
					<Typography textAlign={'center'}>--</Typography>
				}
				<Typography textAlign={'center'}>{title}</Typography>
			</Stack>
		</Box>
	);
}

export default GenericCard;
