import { Button } from "@mui/material";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useState } from "react";
import DateRangePopover from "./DateRangePopover";

export default function DateRangeFilter() {
    const [anchorEl, setAnchorEl] = useState(null);

    const openDatePicker = (e) => {
        setAnchorEl(e.currentTarget);
    }

    const closeDatePicker = () => {
        setAnchorEl(null);
    }

    const handleShowDatePicker = (e) => {
        if (anchorEl) {
            closeDatePicker();
        } else {
            openDatePicker(e);
        }
    }

    return (
        <>
            <Button
                className="filter-btn"
                size="small"
                sx={{
                    backgroundColor: anchorEl ? "primary.accent" : "transparent",
                    zIndex: anchorEl ? 1301 : "auto",
                    marginBottom: "4px",
                }}
                onClick={handleShowDatePicker}
            >
                Date Range
                { 
                    anchorEl ? 
                    <IconChevronUp size={18} stroke={1.5} /> :
                    <IconChevronDown size={18} stroke={1.5} />
                }
            </Button>

            <DateRangePopover 
                anchorEl={anchorEl}
                closeDatePicker={closeDatePicker}
            />
        </>
    );
}