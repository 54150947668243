import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppBar, Box, MenuItem, Select, Skeleton, Stack } from "@mui/material";
import { IconChevronDown } from "@tabler/icons-react";
import { toast } from "react-toastify";
// import { setCurrUserOrgId, USER_SLICE_KEY } from "../../redux/slices/userSlice";
import { USER_SLICE_KEY } from "../../redux/slices/userSlice";
import { fetchUserOrgs } from "./apiFunction";
import { ORG_SLICE_KEY, setCurrOrgId } from "../../redux/slices/orgSlice";
import { useLocation, useNavigate, useParams } from "react-router";

export default function Navbar() {
    const { orgId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    // const [userOrgs, setUserOrgs] = useState(null);
    // const [isUserOrgsLoading, setIsUserOrgsLoading] = useState(false);

    const dispatch = useDispatch();
    // const userState = useSelector(state => state[USER_SLICE_KEY]);
    // const { userData } = userState;
    // const { userData, currUserOrgId } = userState;
    const { userOrgs } = useSelector(state => state[ORG_SLICE_KEY]);

    // const getUserOrgsSuccess = (data) => {
    //     // setUserOrgs(data);
    //     // dispatch(setCurrUserOrgId(data[0].orgId));
    //     // setIsUserOrgsLoading(false);
    // }

    // const getUserOrgsError = (error) => {
    //     toast.error("Unable to fetch user organizations.");
    //     setIsUserOrgsLoading(false);
    // }

    // const getUserOrgs = (userData) => {
        // setIsUserOrgsLoading(true);

        // fetchUserOrgs(
            // { userId: userData.userId },
            // getUserOrgsSuccess,
            // getUserOrgsError
        // )
    // }

    const handleOrgChange = (e) => {
        const newOrgId = e.target.value;
        dispatch(setCurrOrgId(newOrgId));
        localStorage.setItem("orgId", newOrgId);

        if (newOrgId !== orgId) {
            const updatedPath = location.pathname.replace(orgId, newOrgId);
            navigate(updatedPath);
        }
    }

    // useEffect(() => {
        // if (userData) {
            // getUserOrgs(userData);
        // }
    // }, [])

    return (
        <AppBar
            id="navbar"
            position="sticky"
            elevation={0}
        >
            <Box>
                {
                    // userOrgs && !isUserOrgsLoading ? (
                    userOrgs && (
                        <Select 
                            className="navbar-select"
                            placeholder="Select Organization"
                            value={orgId}
                            IconComponent={() => 
                                <Stack sx={{ paddingRight: "12px" }}>
                                    <IconChevronDown className="icon" size={20} />
                                </Stack>
                            }
                            // onChange={(e) => dispatch(setCurrOrgId(e.target.value))}
                            onChange={handleOrgChange}
                        >
                            {
                                userOrgs.map((org, index) => (
                                    <MenuItem key={index} value={org.orgId}>{org.orgName}</MenuItem>
                                ))
                            }
                        </Select>
                    )
                    // ) : (
                    //     <Skeleton 
                    //         variant="rectangular" 
                    //         width={300} 
                    //         height={34} 
                    //         animation="wave"
                    //         sx={{ borderRadius: "8px" }}
                    //     />
                    // )
                }
            </Box>
        </AppBar>
    );
}