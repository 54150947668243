import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Skeleton, Stack, Typography } from "@mui/material";
import { IconClockHour3, IconHeadset, IconLogout, IconPhone, IconUserCircle } from "@tabler/icons-react";
import { toast } from "react-toastify";
import { resetAuthState } from "../../pages/auth/authSlice";
import { USER_SLICE_KEY } from "../../redux/slices/userSlice";
import { removeCookie } from "../../utils/cookies";
import { fetchSidenavConfig } from "./apiFunctions";
import thirdvoiceLogo from "../../assets/logos/thirdvoice.svg";
import "./sideNav.scss";

export default function SideNav() {
    const { orgId } = useParams();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const userData = useSelector(state => state[USER_SLICE_KEY]).userData;

    const [sidenavConfig, setSidenavConfig] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const onGetSidenavConfigSuccess = (data) => {
        setSidenavConfig(data);
        setIsLoading(false);
    }

    const onGetSidenavConfigError = (error) => {
        toast.error("Error occurred while fetching sidenav config!");
        setIsLoading(false);
    }

    const getSidenavConfig = () => {
        setIsLoading(true);

        fetchSidenavConfig(
            {},
            {orgId: orgId},
            onGetSidenavConfigSuccess,
            onGetSidenavConfigError
        )
    }

    const handleLogout = () => {
        removeCookie("accessToken");
        removeCookie("userData");
        localStorage.removeItem("auth-mode");
        localStorage.removeItem("orgId");
        dispatch(resetAuthState());
        toast.success("Logged out!");
        navigate("/login");
    }

    useEffect(() => {
        getSidenavConfig();
    }, []);


    return (
        <Stack
            id="sidenav"
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
        >
            <Stack 
                className="sidenav-sec-1"
                spacing={4}
            >
                <Link
                    className="sidenav-logo-wr"
                    to={`/org/${orgId}/overview`}
                >
                    <img
                        className="sidenav-logo"
                        src={thirdvoiceLogo}
                        alt="Thirdvoice Logo"
                    />
                </Link>

                <Stack
                    className="sidenav-tabs-wr"
                    direction="column"
                >
                    {
                        sidenavConfig && !isLoading ? (
                            sidenavConfig.length > 0 && (
                                sidenavConfig.map((tab, index) => {
                                    const tabIcons = [
                                        <IconClockHour3 size={24} stroke={1.5} />, 
                                        <IconHeadset size={24} stroke={1.5} />, 
                                        <IconPhone size={24} stroke={1.5} />
                                    ];

                                    return (
                                        <NavLink
                                            className={`sidenav-tab-link ${window.location.pathname === "/" && tab.tabName === "Overview" ? "active" : ""}`}
                                            key={tab.tabId}
                                            to={`/org/${orgId}${tab.tabUrl}`} 
                                        >
                                            <Button 
                                                className="sidenav-tab"
                                                fullWidth
                                            >
                                                {tabIcons[index]}
                                                {tab.tabName}
                                            </Button>
                                        </NavLink>   
                                    )
                                })
                            )
                        ) : (
                            new Array(3).fill(null).map((_, index) => (
                                <Stack
                                    key={index}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                    spacing={2}
                                    sx={{ height: "52px", margin: "0 16px" }}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width={24}
                                        height={24}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width="100%"
                                        height={24}
                                    />
                                </Stack>
                            ))
                        )
                    }
                </Stack>
            </Stack>

            <Stack
                className="sidenav-sec-2"
                direction="column"
            >
                {
                    isLoading ? (
                        <Stack
                            className="sidenav-user-info-wr sidenav-tab"
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={2}
                        >
                            <Skeleton
                                variant="rectangular"
                                width={24}
                                height={24}
                            />
                            <Skeleton
                                variant="text"
                                width="100%"
                                height={24}
                            />
                        </Stack>
                    ) : (
                        <Stack
                            className="sidenav-user-info-wr sidenav-tab"
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={1}
                        >
                            <IconUserCircle className="user-avatar-icon" size={24} stroke={1.5} />
                            <Stack direction="row" spacing={0.5}>
                                <Typography variant="body1" color="text.secondary" textTransform="capitalize" fontWeight={500}>{userData?.userData?.firstName}</Typography>
                                <Typography variant="body1" color="text.secondary" textTransform="capitalize" fontWeight={500}>{userData?.userData?.lastName}</Typography>
                            </Stack>
                        </Stack>
                    )
                }

                <Button
                    className="sidenav-tab"
                    variant="text"
                    fullWidth
                    onClick={handleLogout}
                >
                    <IconLogout size={24} stroke={1.5} />
                    <span>Logout</span>
                </Button>
            </Stack>
        </Stack>
    );
}