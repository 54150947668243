import Request from '../../utils/api';
import { overviewSection1, overviewSection2 } from '../../utils/apiRoutes';

const api = new Request();

export const fetchOverViewSection = async (params, headers) => {
	const { response } = await api.get(overviewSection1, params, headers);
	return response;
};
export const fetchGraphData = async (params, headers) => {
	const { response } = await api.get(overviewSection2, params, headers);
	return response;
};
