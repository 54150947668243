import { Button, FormControl, FormControlLabel, Popover, Radio, RadioGroup, Stack } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FILTER_SLICE_KEY, setAppliedFilters, setAppliedFiltersParams } from "./filterSlice";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import "./filters.scss";
import { RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import DateRangeFilter from "./DateRangeFilter";
import AppliedFilters from "./AppliedFilters";

export default function Filters({ showDateRangeFilter }) {
    const { filterData } = useSelector(state => state[FILTER_SLICE_KEY]);
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const [currFilterIndex, setCurrFilterIndex] = useState(null);
    const [currFilter, setCurrFilter] = useState(null);

    const openFilterModal = (e, index, filter) => {
        setAnchorEl(e.currentTarget);
        setCurrFilterIndex(index);
        setCurrFilter(filter);
    }  
    
    const closeFilterModal = () => {
        setCurrFilterIndex(null);
        setAnchorEl(null);
        setCurrFilter(null);
    }

    const handleClickFilterBtn = (e, index, filter) => {
        if (anchorEl && currFilterIndex === index) {
            closeFilterModal();
        } else {
            openFilterModal(e, index, filter);
        }
    }

    const applyFilter = (event, filterName) => {
        const { name, value } = event.target;
        dispatch(setAppliedFilters({ [filterName]: [{ label: name, value: value}] }));
        dispatch(setAppliedFiltersParams({ [filterName]: value }));
    }

    return (
        filterData ? (
            <Stack
                direction="column"
                spacing={1.5}
            >
                <Stack 
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={1.5}
                    width="100%"
                    style={{ marginLeft: "-10px" }}
                >
                    {
                        showDateRangeFilter && 
                        <DateRangeFilter />
                    }
                     
                    {
                        filterData.map((filter, index) => (
                            <Button
                                key={index}
                                className="filter-btn"
                                size="small"
                                sx={{ 
                                    backgroundColor: currFilterIndex === index ? "primary.accent" : "transparent",
                                    zIndex: currFilterIndex === index ? 1301 : "auto",
                                    marginBottom: "4px",
                                }}
                                onClick={(e) => handleClickFilterBtn(e, index, filter)}
                            >
                                {filter.title}
                                { 
                                    currFilterIndex === index ? 
                                    <IconChevronUp size={18} stroke={1.5} /> :
                                    <IconChevronDown size={18} stroke={1.5} />
                                }
                            </Button>
                        ))
                    }
                </Stack>

                <AppliedFilters showDateRange={true} />

                <Popover
                    open={Boolean(anchorEl)}
                    onClose={closeFilterModal}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    slotProps={{ 
                        paper: {
                            sx: { 
                                width: "fit-content",
                                padding: "12px 20px",
                                marginTop: "8px"
                            }
                        } 
                    }}
                >
                    {
                        currFilter?.type === "select" && (
                            <FormControl>
                                <RadioGroup
                                    aria-labelledby={currFilter?.title}
                                    name={currFilter?.name}
                                    onChange={(e) => applyFilter(e, currFilter?.name)}
                                    // value={}
                                    // onChange={}
                                >
                                    {
                                        currFilter?.options.map((option, index) => (
                                            <FormControlLabel
                                                key={index}
                                                className="filter-radio"
                                                control={
                                                    <Radio 
                                                        icon={<RadioButtonUnchecked style={{ fontSize: "16px" }} />}
                                                        checkedIcon={<RadioButtonChecked style={{ fontSize: "16px" }} />}
                                                    />
                                                }
                                                name={option.label}
                                                label={option.label}
                                                value={option.value}
                                            />
                                        ))
                                    }
                                </RadioGroup>
                            </FormControl>
                        )
                    }
                </Popover>
            </Stack>
        ) : null
    );
}