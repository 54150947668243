import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AppBar, Box, Button, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { IconBlockquote, IconCopy, IconLink, IconStars, IconX } from "@tabler/icons-react";
import ReactPlayer from "react-player";
import moment from "moment";
import { USER_SLICE_KEY } from "../../../redux/slices/userSlice";
import { CALL_LOGS_KEY, setCallDetails } from "../callLogsSlice";
import { getLeadStateColor } from "../utilFunctions";
import { copyTextToClipboard } from "../../../utils/common";

export default function CallDetails() {
    const HREF = window.location.href;

    const [isCopied, setIsCopied] = useState({ entity: null, copied: false });
    const [transcriptMessages, setTranscriptMessages] = useState(null);
    const [currTranscriptItemIndex, setCurrTranscriptItemIndex] = useState(0);
    const [showFeedback, setShowFeedback] = useState(false);

    const playerRef = useRef(null);
    const transcriptRef = useRef(null);
    
    const dispatch = useDispatch();
    const { openCallDetails, currentCallDetails } = useSelector(state => state[CALL_LOGS_KEY]);
    const { userData } = useSelector(state => state)[USER_SLICE_KEY];

    const navigate = useNavigate();

    const handleClose = () => {
        dispatch(setCallDetails({ openCallDetails: false, currentCallDetails: null }));
        navigate("/call-logs");
    };

    const formatSeconds = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = Math.floor(seconds % 60);
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    };

    const highlightTranscript = (playedSeconds) => {
        let flag = false;

        if (transcriptMessages) {
            for (let i = 0; i < transcriptMessages.length; i++) {
                const currItem = transcriptMessages[i];
                const nextItem = i + 1 < transcriptMessages.length ? transcriptMessages[i + 1] : null;

                if (playedSeconds >= currItem.secondsFromStart && playedSeconds < (nextItem ? nextItem.secondsFromStart : Infinity)) {
                    setCurrTranscriptItemIndex(i);
                    break;
                }
            }

            if (!flag && playedSeconds >= transcriptMessages[transcriptMessages.length - 1].secondsFromStart) {
                setCurrTranscriptItemIndex(transcriptMessages.length - 1);
            }
        }
    };

    const handleManualSeek = (index) => {
        if (transcriptMessages) {
            const item = transcriptMessages[index];
            playerRef.current.seekTo(item.secondsFromStart);
            setCurrTranscriptItemIndex(index);
        }
    };

    const getTranscriptText = (transcriptArray) => {
        let transcriptText = "";
        for (let i = 0; i < transcriptArray.length; i++) {
            if (transcriptArray[i].role === "bot" || transcriptArray[i].role === "user") {
                transcriptText += `${formatSeconds(transcriptArray[i].secondsFromStart)} | ${transcriptArray[i].role === "bot" ? "Bot" : "User"}: ${transcriptArray[i].message}\n`;
            }
        }

        return transcriptText;
    }

    useEffect(() => {
        if (currentCallDetails) {
            let messages = currentCallDetails.messages;
            messages = messages.filter((item) => (item.role === "bot" || item.role === "user") && item.message);
            setTranscriptMessages(messages);
        }
    }, [currentCallDetails]);

    useEffect(() => {
        if (transcriptRef.current) {
            transcriptRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
        }
    }, [currTranscriptItemIndex]);

    return (
        currentCallDetails ? (
            <Dialog 
                className="call-details-wr"
                open={openCallDetails}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
            >
                <AppBar 
                    className="cd-appbar"
                    position="sticky"
                    elevation={0}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                    >
                        <DialogTitle>Call Details</DialogTitle>

                        <Stack direction="row" spacing={1}>
                            <Tooltip 
                                title={(isCopied.copied && isCopied.entity === "link") ? "Link Copied!" : "Copy Link"}
                                arrow
                                placement="left"
                            >
                                <IconButton onClick={() => copyTextToClipboard(HREF, setIsCopied, "link")}>
                                    <IconLink size={20} stroke={2} />
                                </IconButton>
                            </Tooltip>
                            <IconButton onClick={handleClose}>
                                <IconX size={20} stroke={2} />
                            </IconButton>
                        </Stack>
                    </Stack>
                </AppBar>

                <DialogContent className="cd-content" dividers>
                    <Grid container>
                        <Grid className="cd-sidebar" item xs={4}>
                            {
                                currentCallDetails ? (
                                    <Stack 
                                        className="cd-sidebar-content"
                                        direction="column" 
                                        justifyContent="space-between"
                                        spacing={3}
                                    >
                                        <Stack
                                            direction="column"
                                            gap="16px"
                                        >
                                            <Grid container spacing={2}>
                                                <Grid item xs={3.5}>
                                                    <Typography variant="body2" color="text.tertiary">Call Id</Typography>
                                                </Grid>
                                                <Grid item xs={8.5}>
                                                    <Typography variant="body2">
                                                        {currentCallDetails.id ? currentCallDetails.id : "--"}
                                                        {
                                                            currentCallDetails.id && (
                                                                <span>
                                                                    <Tooltip
                                                                        title={(isCopied.copied && isCopied.entity === "call_id") ? "Id Copied!" : "Copy Id"}
                                                                        arrow
                                                                        placement="right"
                                                                    >
                                                                        <IconButton
                                                                            sx={{ padding: "4px", marginLeft: "4px" }}
                                                                            onClick={() => copyTextToClipboard(currentCallDetails.id, setIsCopied, "call_id")}
                                                                        >
                                                                            <IconCopy size={14} stroke={1.5} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                            )
                                                        }    
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3.5}>
                                                    <Typography variant="body2" color="text.tertiary">Lead</Typography>
                                                </Grid>
                                                <Grid item xs={8.5}>
                                                    <Typography variant="body2">
                                                        {
                                                            currentCallDetails.leadStatus ? (
                                                                <Chip
                                                                    label={(currentCallDetails.leadStatus).split(" ")[0]}
                                                                    variant="outlined"
                                                                    color={getLeadStateColor(currentCallDetails.leadStatus)}
                                                                />
                                                            ) : "--"
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3.5}>
                                                    <Typography variant="body2" color="text.tertiary">Caller Name</Typography>
                                                </Grid>
                                                <Grid item xs={8.5}>
                                                    <Typography variant="body2">
                                                        {currentCallDetails.callerName ? currentCallDetails.callerName : "--"}
                                                        {
                                                            (currentCallDetails.callerName && currentCallDetails.callerName !== "-NA-") && (
                                                                <span>
                                                                    <Tooltip
                                                                        title={(isCopied.copied && isCopied.entity === "caller_name") ? "Name Copied!" : "Copy Name"}
                                                                        arrow
                                                                        placement="right"
                                                                    >
                                                                        <IconButton
                                                                            sx={{ padding: "4px", marginLeft: "4px" }}
                                                                            onClick={() => copyTextToClipboard(currentCallDetails.callerName, setIsCopied, "caller_name")}
                                                                        >
                                                                            <IconCopy size={14} stroke={1.5} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                            )
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3.5}>
                                                    <Typography variant="body2" color="text.tertiary">Phone No.</Typography>
                                                </Grid>
                                                <Grid item xs={8.5}>
                                                    <Typography variant="body2">
                                                        {currentCallDetails.customer?.number ? currentCallDetails.customer.number : "--"}
                                                        {
                                                            (currentCallDetails.customer?.number && currentCallDetails.customer?.number !== "Web Call") && (
                                                                <span>
                                                                    <Tooltip
                                                                    title={(isCopied.copied && isCopied.entity === "phone_number") ? "Number Copied!" : "Copy Number"}
                                                                    arrow
                                                                    placement="right"
                                                                >
                                                                    <IconButton
                                                                        sx={{ padding: "4px", marginLeft: "4px" }}
                                                                        onClick={() => copyTextToClipboard(currentCallDetails.customer.number, setIsCopied, "phone_number")}
                                                                    >
                                                                        <IconCopy size={14} stroke={1.5} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                </span>
                                                            )
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3.5}>
                                                    <Typography variant="body2" color="text.tertiary">Date ; Time</Typography>
                                                </Grid>
                                                <Grid item xs={8.5}>
                                                    <Typography variant="body2">{moment(currentCallDetails.createdAt).format("DD-MM-YYYY ; hh:mm A")}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3.5}>
                                                    <Typography variant="body2" color="text.tertiary">Status</Typography>
                                                </Grid>
                                                <Grid item xs={8.5}>
                                                    <Typography variant="body2" textTransform="capitalize">{currentCallDetails.status ? currentCallDetails.status : "--"}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3.5}>
                                                    <Typography variant="body2" color="text.tertiary">Summary</Typography>
                                                </Grid>
                                                <Grid item xs={8.5}>
                                                    <Typography variant="body2">
                                                        {currentCallDetails.summary ? currentCallDetails.summary : "--"}
                                                        {
                                                            currentCallDetails.summary && (
                                                                <span>
                                                                    <Tooltip
                                                                        title={(isCopied.copied && isCopied.entity === "summary") ? "Summary Copied!" : "Copy Summary"}
                                                                        arrow
                                                                        placement="right"
                                                                    >
                                                                        <IconButton
                                                                            sx={{ padding: "4px", marginLeft: "4px" }}
                                                                            onClick={() => copyTextToClipboard(currentCallDetails.summary, setIsCopied, "summary")}
                                                                        >
                                                                            <IconCopy size={14} stroke={1.5} />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                            )
                                                        }    
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Stack>

                                        <Button
                                            variant="outlined"
                                            fullWidth
                                            onClick={() => setShowFeedback(!showFeedback)}
                                        >
                                            {
                                                showFeedback ? (
                                                    <>
                                                        <IconBlockquote size={20} stroke={1.5} />
                                                        <span>Transcript</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <IconStars size={20} stroke={1.5} />
                                                        <span>Feedback</span>
                                                    </>
                                                )
                                            }
                                        </Button>
                                    </Stack>
                                ) : (
                                    <CircularProgress size={24} />
                                )
                            }
                        </Grid>
                        <Grid className="cd-main" item xs={8}>
                            <Stack 
                                className="cd-main-content"
                                direction="column" 
                                spacing={1}
                            >
                                <Stack 
                                    className="cd-player-wr"
                                    direction="column" 
                                    spacing={2} 
                                >
                                    {
                                        currentCallDetails.recordingUrl && (
                                            <ReactPlayer
                                                ref={playerRef}
                                                url={currentCallDetails.recordingUrl}
                                                controls
                                                width="100%"
                                                height="40px"
                                                onProgress={(a) => highlightTranscript(a.playedSeconds)}
                                            />
                                        )
                                    }
                                </Stack>
                                
                                {
                                    showFeedback ? (
                                        <Stack className="cd-feedback-wr">
                                            <Typography 
                                                component="h3" 
                                                variant="h6"
                                                fontSize="16px"
                                                color="text.secondary"
                                                padding={"8px 20px 8px 24px"}
                                            >
                                                Feedback
                                            </Typography>

                                            <Box className="cd-feedback-content">
                                                <iframe
                                                    className="cd-feedback-iframe"
                                                    src={`https://mybliss.typeform.com/to/uzBebJEH#name=${userData?.userData?.firstName || userData?.userData?.lastName || "Hello"}&user_id=${userData?.userId || "xxxxx"}&dashboard_url=${HREF + "?id=" + currentCallDetails?.id}&call_id=${currentCallDetails?.id || "xxxxx"}&assistant_id=${currentCallDetails?.assistantId || "xxxxx"}`}
                                                    title="Feedback Form"
                                                />
                                            </Box>
                                        </Stack>
                                    ) : (
                                        <Stack 
                                            className="cd-transcript-wr"
                                            direction="column"
                                        >
                                            <Typography 
                                                component="h3" 
                                                variant="h6"
                                                fontSize="16px"
                                                color="text.secondary"
                                                padding={"8px 20px 8px 24px"}
                                            >
                                                Transcript

                                                {
                                                    transcriptMessages && (
                                                        <span>
                                                            <Tooltip 
                                                                title={(isCopied.copied && isCopied.entity === "transcript") ? "Transcript Copied!" : "Copy Transcript"}
                                                                arrow
                                                                placement="right"
                                                            >
                                                                <IconButton 
                                                                    sx={{ marginLeft: "4px" }}
                                                                    onClick={() => copyTextToClipboard(getTranscriptText(transcriptMessages), setIsCopied, "transcript")}
                                                                >
                                                                    <IconCopy size={16} stroke={1.5} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </span>
                                                    )
                                                }
                                            </Typography>
                                            <Stack className="cd-transcript-content" direction="column">
                                                {
                                                    transcriptMessages && (
                                                        transcriptMessages.length > 0 ? (
                                                            transcriptMessages.map((item, index) => {
                                                                return (
                                                                    ((item.role === "bot" || item.role === "user") && item.message) &&
                                                                    <Grid
                                                                        className="cd-transcript-item"
                                                                        key={index}
                                                                        container
                                                                        ref={index === currTranscriptItemIndex ? transcriptRef : null}
                                                                        sx={{ backgroundColor: currTranscriptItemIndex === index ? "grey.contentBg" : "transparent" }}
                                                                        onClick={() => handleManualSeek(index)}
                                                                    >
                                                                        <Grid item xs={1.5}>
                                                                            <Button variant="text" size="small">
                                                                                {formatSeconds(item.secondsFromStart)}
                                                                            </Button>
                                                                        </Grid>
                                                                        <Grid item xs={10.5}>
                                                                            <Stack 
                                                                                direction="row" 
                                                                                spacing={1}
                                                                                sx={{ paddingTop: "4px" }}
                                                                            >
                                                                                <Typography color="text.tertiary" variant="body2">{item.role === "bot" ? "Bot" : "User"}:</Typography> 
                                                                                <Typography variant="body2">{item.message}</Typography>
                                                                            </Stack>
                                                                        </Grid>
                                                                    </Grid>
                                                                )
                                                            })
                                                        ) : (   
                                                            <CircularProgress size={28} />
                                                        )
                                                    )
                                                }
                                            </Stack>
                                        </Stack>
                                    )
                                }
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        ) : null
    );
}
