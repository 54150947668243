import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../pages/auth/authSlice';
import userReducer from './slices/userSlice';
import orgReducer from './slices/orgSlice';
import overviewReducer from '../pages/overview/overviewSlice';
import callLogsReducer from '../pages/callLogs/callLogsSlice';
import filterReducer from '../components/filters/filterSlice';

export const REDUCER_KEYS = {
	AUTH: 'auth',
	USER: 'user',
	ORG: 'org',
	OVERVIEW: 'overview',
	CALL_LOGS: 'callLogs',
	FILTERS: 'filters',
};

export const store = configureStore({
	reducer: {
		auth: authReducer,
		user: userReducer,
		org: orgReducer,
		overview: overviewReducer,
		callLogs: callLogsReducer,
		filters: filterReducer
	},
});
