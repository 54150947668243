import { addDays } from "date-fns";

export const getLeadStateColor = (state) => {
    switch (state) {
        case "New Lead":
            return "success";
        case "Returning":
            return "warning";
        default:
            return "error";
    }
};