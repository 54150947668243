import { createSlice } from "@reduxjs/toolkit";

export const CALL_LOGS_KEY = "callLogs";

export const callLogsSlice = createSlice({
    name: CALL_LOGS_KEY,
    initialState: {
        openCallDetails: false,
        currentCallDetails: null,
    },
    reducers: {
        setCallDetails: (state, action) => {
            state.openCallDetails = action.payload.openCallDetails;
            state.currentCallDetails = action.payload.currentCallDetails;
        },
    },
});

export const { setCallDetails } = callLogsSlice.actions;
export default callLogsSlice.reducer;