import { createSlice } from "@reduxjs/toolkit";
import { getDateRangeString, setDateRangeObj } from "./utilFunctions";

export const FILTER_SLICE_KEY = "filters";

export const filterSlice = createSlice({
    name: FILTER_SLICE_KEY,
    initialState: {
        filterData: null,
        filterModal: {
            open: false,
            currFilter: null
        },
        appliedFilters: null,
        appliedFiltersParams: null,
        appliedDateRangeString: "Last 30 Days",
        appliedDateRangeParams: setDateRangeObj()
    },
    reducers: {
        setFilterData: (state, action) => {
            state.filterData = action.payload;
        },
        setFilterModal: (state, action) => {
            state.filterModal = action.payload;
        },
        setAppliedFilters: (state, action) => {
            state.appliedFilters = {
                ...state.appliedFilters,
                ...action.payload
            };
        },
        setAppliedFiltersParams: (state, action) => {
            state.appliedFiltersParams = {
                ...state.appliedFiltersParams,
                ...action.payload
            }
        },
        removeAppliedFilter: (state, action) => {
            const filterName = action.payload;
            const { [filterName]: _, ...newAppliedFilters } = state.appliedFilters;
            const { [filterName]: __, ...newAppliedFiltersParams } = state.appliedFiltersParams;

            state.appliedFilters = newAppliedFilters;
            state.appliedFiltersParams = newAppliedFiltersParams;
        },
        setAppliedDateRange: (state, action) => {
            state.appliedDateRangeParams = action.payload;
            state.appliedDateRangeString = getDateRangeString(action.payload);
        },
        removeAppliedDateRange: (state) => {
            state.appliedDateRangeString = null;
            state.appliedDateRangeParams = null;
        }
    },
});

export const { 
    setFilterData, 
    setAppliedFilters, 
    setAppliedFiltersParams,
    removeAppliedFilter,
    setAppliedDateRange,
    removeAppliedDateRange
} = filterSlice.actions;
export default filterSlice.reducer;