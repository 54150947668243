import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircularProgress, FormControl, InputLabel, OutlinedInput, Stack, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { setCookie } from "../../../utils/cookies";
import { verifyCode } from "../apiFunctions";
import { setUserData } from "../../../redux/slices/userSlice";
// import { setUserData, setUserOrgs } from "../../../redux/slices/userSlice";
import { AUTH_SLICE_KEY, resetAuthState, setAuthStep, setIsAuth, setVerificationCode } from "../authSlice";
import { setCurrOrgId, setUserOrgs } from "../../../redux/slices/orgSlice";

export default function AuthStep3 () {
    const navigate = useNavigate();
    const location = useLocation();

    const [verifying, setVerifying] = useState(false);
    const [resendCodeTimer, setResendCodeTimer] = useState(60);

    const dispatch = useDispatch();
    const authState = useSelector((state) => state[AUTH_SLICE_KEY]);
    const { authVia, phoneCountryCode, phoneNumber, email, verificationCode } = authState;

    const onCodeInputChange = (event) => {
        const code = event.target.value;
        
        if (code.length <= 6) {
            dispatch(setVerificationCode(code));
        } else {
            event.preventDefault();
        }
    }

    const onVerificationSuccess = (data) => {
        localStorage.removeItem("auth-mode");
        dispatch(resetAuthState());

        let newOrdId = null;
        if (data.userData) {
            const userDataStr = JSON.stringify(data.userData);
            dispatch(setUserData(data.userData));
            setCookie("userData", userDataStr, 1);

            newOrdId = data.userData.userData.orgId;
        }

        if (data.userOrgs) {
            const userOrgsStr = JSON.stringify(data.userOrgs);
            dispatch(setUserOrgs(data.userOrgs));
            setCookie("userOrgs", userOrgsStr, 1);
        }

        dispatch(setIsAuth(true));

        toast.success("Verification successful!");
        setVerifying(false);

        const temp = localStorage.getItem("orgId");
        const orgId = temp ? temp : newOrdId;

        dispatch(setCurrOrgId(orgId));
        if (!temp) {
            localStorage.setItem("orgId", orgId);
        }

        navigate(location?.state?.from ? location.state.from : `org/${orgId}/overview`, { replace: true })
    }

    const onVerificationError = () => {
        setVerifying(false);
        toast.error("Unable to verify code. Please try again.");
    }

    const handleVerification = () => {
        setVerifying(true);
        let postPayload = {verificationCode: verificationCode};

        if (authVia === "text") {
            postPayload = {
                ...postPayload,
                phoneContact: {
                    number: phoneNumber,
                    countryCode: phoneCountryCode
                }
            }
        } else if (authVia === "email") {
            postPayload = {
                ...postPayload,
                emailContact: {
                    email: email
                }
            }
        }

        if (verificationCode.length === 6) {
            verifyCode(postPayload, onVerificationSuccess, onVerificationError);
        } else {
            toast.error("Invalid verification code. Please try again.");
            setVerifying(false);
        }
    }

    const handleResendCode = () => {
        setResendCodeTimer(60);
        handleVerification();
    }

    useEffect(() => {
        if (resendCodeTimer > 0) {
            setTimeout(() => setResendCodeTimer(resendCodeTimer - 1), 1000);
        }
    }, [resendCodeTimer]);

    return (
        <Stack
            className="auth-step-3"
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={3}
        >
            <Stack
                className="auth-step-3-content"
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Stack 
                    className="auth-step-prompt"
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography variant="body2">Enter verification code sent to</Typography>
                    <Typography variant="body2" fontWeight={700}>
                        {authVia === "text" && `+${phoneCountryCode} ${phoneNumber}`}
                        {authVia === "email" && email}
                    </Typography>
                </Stack>

                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="verification-code">Verification Code</InputLabel>
                    <OutlinedInput
                        id="verification-code"
                        label="Verification Code"
                        type="text"
                        autoFocus   
                        required
                        value={verificationCode}
                        onChange={onCodeInputChange}
                        sx={{
                            "& input": {
                                textAlign: "center",
                                fontWeight: 700,
                                letterSpacing: "24px"
                            }
                        }}
                    />
                </FormControl>

                <Button
                    variant="contained"
                    fullWidth
                    disabled={verifying || verificationCode.length !== 6}
                    onClick={handleVerification}
                >
                    {verifying && <CircularProgress size={16} />}
                    <span>{verifying ? "Verifying Code..." : "Verify Code"}</span>
                </Button>

                <Button
                    variant="text"
                    fullWidth
                    onClick={() => dispatch(setAuthStep(2))}
                >
                    Back
                </Button>
            </Stack>

            <Stack  
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0.5}
            >
                <Typography variant="body2">Didn't receive the code?</Typography>
                {
                    resendCodeTimer === 0 ? (
                        <Button variant="text" onClick={handleResendCode}>Resend Code</Button>
                    ) : (
                        <Typography variant="body2" color="primary.main">Resend in {resendCodeTimer} sec</Typography>
                    )
                }
            </Stack>
        </Stack>
    );
}