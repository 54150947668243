import { addDays } from "date-fns";
import moment from "moment";

export const setDateRangeObj = () => {
	return [{ startDate: addDays(new Date(), -30), endDate: new Date(), key: 'selection' }];
};

export const formatDate = (date) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let day = date.getDate();
    let month = months[date.getMonth()];
    let year = date.getFullYear();

    // Ensure day is two digits
    if (day < 10) {
        day = '0' + day;
    }

    return `${month} ${day}, ${year}`;
}

export const getDateRangeString = (dateRange) => {
    const { startDate, endDate } = dateRange[0];
    let sd = new Date(startDate);
    let ed = new Date(endDate);
    let dateRangeStr = "";

    if (sd.toDateString() === ed.toDateString()) {
        dateRangeStr = "" + formatDate(sd);
    } else {
        dateRangeStr = `${formatDate(sd)} to ${formatDate(ed)}`;
    }

    return dateRangeStr;
}

export const setDateParams = (dateRange, format, key1, key2) => {
    if (!dateRange) {
        return null;
    }
    
    const from = moment(dateRange[0].startDate).format(format);
    const to = moment(dateRange[0].endDate).format("YYYY-MM-DD");
    
    return {
        [key1]: from,
        [key2]: to
    };
}