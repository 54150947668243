import { createSlice } from "@reduxjs/toolkit";

export const USER_SLICE_KEY = "user";

export const userSlice = createSlice({
    name: USER_SLICE_KEY,
    initialState: {
        orgId: null,
        userData: null,
        // userOrgs: null,
        // currUserOrgId: null,
    },
    reducers: {
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        // setUserOrgs: (state, action) => {
        //     state.userOrgs = action.payload;
        // },
        // setCurrUserOrgId: (state, action) => {
        //     state.currUserOrgId = action.payload;
        // },
    },
});

export const { setUserData } = userSlice.actions;
// export const { setUserData, setUserOrgs, setCurrUserOrgId } = userSlice.actions;
export default userSlice.reducer;