import React, { useEffect, useState } from 'react';
import { Stack, Box, Fade } from '@mui/material';
import { getAssistantsList } from './apiFunctions';
import { AssistantsCard, AssistantsSkeleton } from './components';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ORG_SLICE_KEY } from '../../redux/slices/orgSlice';

function Assistants() {
    const [assistantsList, setAssistantsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { currorgId } = useSelector(state => state[ORG_SLICE_KEY]);

    const onFetchAssistantsSuccess = (data) => {
        setAssistantsList(data);
        setIsLoading(false);
    }

    const onFetchAssistantsError = (error) => {
        toast.error("Error occurred while fetching assistants!");
        setIsLoading(false);
    }

    const fetchAssistants = async () => {
        setIsLoading(true);
        getAssistantsList(
            {isActive: true},
            {orgId: currorgId},
            onFetchAssistantsSuccess,
            onFetchAssistantsError
        );
    }

    useEffect(() => {
        fetchAssistants();
    }, [])

    if (isLoading) {
        return <AssistantsSkeleton />
    }

    return (
        <Fade
            in={true}
            timeout={2500}
        >
            <Box
                padding={2}
            >
                <Stack
                    direction={'column'}
                    spacing={2}
                >
                    {
                        assistantsList.map((assistant, idx) => (
                            assistant.isActive ? (
                                <AssistantsCard index={idx + 1} key={assistant.id} data={assistant} />
                            ) : null
                        ))
                    }
                </Stack>
            </Box>
        </Fade>
    )
}

export default Assistants
