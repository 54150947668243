import React, { Suspense, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Grid, Typography, Stack, Popover, Fade } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment/moment';
import { BarGraph, GenericCard, PieGraph, OverviewSkeleton } from './components';
import { updateDate } from './overviewSlice';
import { REDUCER_KEYS } from '../../redux/store';
import { fetchGraphData, fetchOverViewSection } from './apiFunction';
import { setIntialState } from './utilFunctions';
import { TRANSITION_TIME_OUT } from '../../utils/constants';

// react-date-range
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRangePopover from '../../components/filters/DateRangePopover';
import { setDateParams } from '../../components/filters/utilFunctions';
import { FILTER_SLICE_KEY } from '../../components/filters/filterSlice';
import { ORG_SLICE_KEY } from '../../redux/slices/orgSlice';
import { useParams } from 'react-router';

export default function Overview() {
	const { orgId } = useParams();

	const { OVERVIEW } = REDUCER_KEYS;
	const rangeSlice = useSelector((state) => state[OVERVIEW]);
	const [range, setRange] = useState(setIntialState());
	const dispatch = useDispatch();
	const [showDatePicker, setShowDatePicker] = useState(false);
	const [btnText, setBtnText] = useState('Last 30 days');
	const [genericCardList, setGenericCardList] = useState([]);
	const [barChartData, setBarChartData] = useState([]);
	const [pieChartData, setPieCharData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [anchorEl, setAnchorEl] = useState(null);

	const { appliedDateRangeParams, appliedDateRangeString} = useSelector(state => state[FILTER_SLICE_KEY]);

	async function getSectionData(params) {
		const { data } = await fetchOverViewSection(params, {orgId: orgId});
		setGenericCardList(data.data || []);
		setIsLoading(false);
	}
	async function getGraphData(params) {
		const { data } = await fetchGraphData(params, {orgId: orgId});
		const { barChartData, pieChartData } = data.data || {};
		setBarChartData(barChartData || []);
		setPieCharData(pieChartData || []);
		setIsLoading(false);
	}

	const handleOpenDatePicker = (e) => {
		setShowDatePicker((state) => !state);
		setAnchorEl(e.currentTarget);
	};

	const handleDatePickerClose = () => {
		setRange(rangeSlice);
		setShowDatePicker(false);
	};

	// function formatDate(date) {
	// 	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	// 	let day = date.getDate();
	// 	let month = months[date.getMonth()];
	// 	let year = date.getFullYear();

	// 	// Ensure day is two digits
	// 	if (day < 10) {
	// 		day = '0' + day;
	// 	}

	// 	return `${month} ${day}, ${year}`;
	// }

	// const handleSelectDate = () => {
	// 	const { startDate, endDate } = range[0];
	// 	let sd = new Date(startDate);
	// 	let ed = new Date(endDate);
	// 	const btnTxt = "" + formatDate(sd) + " - " + formatDate(ed)
	// 	setBtnText(btnTxt)
	// 	dispatch(updateDate(range));
	// 	setShowDatePicker(false);
	// };

	// const setDateParams = () => {
	// 	// const sY = new Date(range[0].startDate).getFullYear();
	// 	// const eY = new Date(range[0].endDate).getFullYear();

	// 	// const sM = new Date(range[0].startDate).getMonth() + 1;
	// 	// const eM = new Date(range[0].endDate).getMonth() + 1;

	// 	// const sD = new Date(range[0].startDate).getDate();
	// 	// const eD = new Date(range[0].endDate).getDate();

	// 	// const startDate = sY + '/' + sM + '/' + sD;
	// 	// const endDate = eY + '/' + eM + '/' + eD;
	// 	const startDate = moment(range[0].startDate).format('YYYY/MM/DD');
	// 	const endDate = moment(range[0].endDate).format('YYYY/MM/DD');

	// 	const params = {
	// 		startDate,
	// 		endDate,
	// 	};

	// 	return params;
	// };

	useEffect(() => {
		// const params = setDateParams();
		const params = setDateParams(appliedDateRangeParams, 'YYYY/MM/DD', 'startDate', 'endDate');
		getSectionData(params);
		getGraphData(params);
	}, [appliedDateRangeParams]);

	if (isLoading) return <OverviewSkeleton />;

	return (
		<Suspense fallback={<div>Loading</div>}>
			<Box sx={{ margin: "16px" }}>
				<Fade
					in={true}
					timeout={TRANSITION_TIME_OUT}
				>
					<Box sx={{ marginTop: '16px' }}>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
						>
							<Typography variant='body2'>Display data from</Typography>
							<Button
								sx={{ marginLeft: '8px', backgroundColor: 'white' }}
								size="medium"
								onClick={handleOpenDatePicker}
								variant="outlined"
								endIcon={anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							>
								<Typography 
									variant='body2'
									style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
								>
									{appliedDateRangeString}
								</Typography>
							</Button>
							<DateRangePopover 
								anchorEl={anchorEl}
								closeDatePicker={() => setAnchorEl(null)}
							/>
							{/* <Popover
								sx={{
									marginTop: '8px',
								}}
								anchorEl={anchorEl}
								open={showDatePicker}
								onClose={handleDatePickerClose}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
							>
								<Typography>
									<DateRangePicker
										value={range}
										onChange={(item) => setRange([item.selection])}
										showSelectionPreview={true}
										moveRangeOnFirstSelection={false}
										months={2}
										ranges={range}
										direction="horizontal"
										rangeColors={['#9E2D63', '#9E2D63', '#D44D4D']}
										maxDate={new Date()}
									/>
									<Stack
										direction={'row'}
										display={'flex'}
										justifyContent={'flex-end'}
										alignItems={'space-around'}
										padding={'8px'}
										sx={{
											marginBottom: '12px',
										}}
									>
										<Button
											sx={{ marginLeft: '8px' }}
											size="medium"
											variant="contained"
											onClick={handleSelectDate}
										>
											Done
										</Button>
										<Button
											sx={{ marginLeft: '8px' }}
											size="medium"
											variant="outlined"
											onClick={handleDatePickerClose}
										>
											Close
										</Button>
									</Stack>
								</Typography>
							</Popover> */}
						</Stack>
					</Box>
				</Fade>

				<Fade
					in={true}
					timeout={TRANSITION_TIME_OUT}
				>
					<Box sx={{ marginTop: '16px' }}>
						<Grid
							container
							columnSpacing={2}
							rowSpacing={2}
						>
							{genericCardList.map((item) => (
								<Grid
									item
									xs={2}
									key={item.title}
								>
									<Box>
										<GenericCard
											title={item.title}
											value={item.value}
										/>
									</Box>
								</Grid>
							))}

							<Grid
								item
								xs={4}
							>
								<Box>
									<GenericCard
										title={""}
										value={""}
									/>
								</Box>
							</Grid>

						</Grid>
					</Box>
				</Fade>

				<Fade
					in={true}
					timeout={TRANSITION_TIME_OUT}
				>
					<Box sx={{ marginTop: '16px' }}>
						<Grid
							container
							columnSpacing={2}
						>
							{barChartData.length !== 0 ? (
								<>
									<Grid
										item
										xs={6}
									>
										<BarGraph data={barChartData} />
									</Grid>
								</>
							) : null}

							{pieChartData.length !== 0 ? (
								<>
									<Grid
										item
										xs={6}
									>
										<PieGraph data={pieChartData} />
									</Grid>
								</>
							) : null}
						</Grid>
					</Box>
				</Fade>
			</Box>
		</Suspense>
	);
}
