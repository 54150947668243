import { Button, Popover, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import { setDateRangeObj } from "./utilFunctions";

import "./filters.scss";
import { useDispatch } from "react-redux";
import { setAppliedDateRange } from "./filterSlice";

export default function DateRangePopover({ anchorEl, closeDatePicker }) {
    const dispatch = useDispatch();

    const [dateRange, setDateRange]= useState(setDateRangeObj());

    const applyDateRange = () => {
        dispatch(setAppliedDateRange(dateRange));
        closeDatePicker();
    }

    return (
        <Popover
            className="date-range-popover"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeDatePicker}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            slotProps={{ 
                paper: {
                    sx: { 
                        width: "fit-content",
                        marginTop: "8px"
                    }
                } 
            }}
        >
            <Typography>
                <DateRangePicker
                    value={dateRange}
                    onChange={(item) => setDateRange([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={dateRange}
                    direction="horizontal"
                    rangeColors={['#F5EDF1', '#9e2d63', '#9e2d63']}
                    maxDate={new Date()}
                />

                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                    padding="8px 12px"
                >
                    
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{ borderRadius: "2rem"}}
                        onClick={applyDateRange}
                    >
                        Apply
                    </Button>

                    <Button 
                        className="filter-btn"
                        onClick={closeDatePicker}
                    >
                        Cancel
                    </Button>
                </Stack>
            </Typography>
        </Popover>
    );
}
