import { Navigate, Outlet, useLocation } from "react-router";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { Navbar, SideNav} from "../components";
import { AUTH_SLICE_KEY } from "../pages/auth/authSlice";

export default function PrivateRoutes() {
    const location = useLocation();
    
    const isAuth = useSelector(state => state[AUTH_SLICE_KEY]).isAuth;

    return (
        isAuth ? (
            <main>
                <Grid container>
                    <Grid 
                        className="side-nav-wr"
                        item xs={2}
                    >
                        <SideNav />
                    </Grid>
                    <Grid item xs={10}>
                        <Navbar />
                        <Box 
                            className="site-content-wr"
                            sx={{ height: `calc(100vh - 102px)` }}
                        >
                            <Outlet />
                        </Box>
                    </Grid>
                </Grid>
            </main>
        ) : (
            <Navigate to="/login" state={{ from: location }} replace />
        )
    )
}